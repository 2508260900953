import { useEffect, useRef } from 'react';

export default function useScrollRestoration(router) {
  const isBack = useRef(false);
  useEffect(() => {
    router.beforePopState(() => {
      isBack.current = true;
      return true;
    });

    const onRouteChangeStart = () => {
      if (sessionStorage) {
        const url = router.asPath;
        const scrollPos = {
          x: window.scrollX,
          y: url.includes('p/1') ? window.scrollY - 130 : window.scrollY
        };
        sessionStorage.setItem(url, JSON.stringify(scrollPos));
      }
    };

    const onRouteChangeComplete = (url) => {
      if (sessionStorage) {
        const scrollPos = JSON.parse(sessionStorage.getItem(url));
        if (isBack.current && scrollPos) {
          window.scroll({
            top: scrollPos.y,
            behavior: 'auto'
          });
        }
      }
      isBack.current = false;
    };

    router.events.on('routeChangeStart', onRouteChangeStart);
    router.events.on('routeChangeComplete', onRouteChangeComplete);

    return () => {
      router.events.off('routeChangeStart', onRouteChangeStart);
      router.events.off('routeChangeComplete', onRouteChangeComplete);
    };
  }, [router]);
}
