/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  hasVideo: false,
  error: false,
  finishedCountdown: false,
  highlighted: '',
  productsInMetaData: []
};

const checkoutSlice = createSlice({
  name: 'streaming',
  initialState,
  reducers: {
    addProductInMetaData(state, action) {
      state.productsInMetaData = [action.payload, ...state.productsInMetaData];
    },
    finishedCountdown(state, action) {
      state.finishedCountdown = action.payload;
    },
    highlightedProduct(state, action) {
      state.highlighted = action.payload;
    },
    stateIvs(state, action) {
      return { ...state, ...action.payload };
    }
  }
});
export const {
  addProductInMetaData,
  finishedCountdown,
  highlightedProduct,
  stateIvs
} = checkoutSlice.actions;
export default checkoutSlice.reducer;
