import { useState, useEffect } from 'react';
import apiLive from '@/api/ivs/live';
import { finishedCountdown, stateIvs } from '@/redux/streaming';

function useLiveInfo(store) {
  const [live, setLive] = useState({});
  useEffect(() => {
    (async () => {
      const resp = await apiLive.getInfo();
      if (!resp.hasError) {
        setLive(resp || {});
        store.dispatch(
          stateIvs({ hasVideo: resp.live, error: false, viewers: resp.viewers })
        );
        store.dispatch(finishedCountdown(resp.finishCountdown));
      }
    })();
  }, []);
  return live;
}
export default useLiveInfo;
