import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const storebranchesSlice = createSlice({
  name: 'storebranches',
  initialState
});
// const reducer = (state = initialState) => state;

export default storebranchesSlice.reducer;
