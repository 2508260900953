import parsePriceToNumber from '@/utils/parsePriceToNumber';

/* eslint-disable camelcase */
export default function normalizeProduct(productDetail) {
  const { sku, name, brand_name, business, price } = productDetail;

  return {
    id: sku,
    name,
    price: parsePriceToNumber(price),
    brand: brand_name,
    category: business
  };
}
