/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  items: [],
  pagination: {
    total: 0,
    current: 1
  },
  filtersOptions: null,
  filtersActive: null,
  orderBy: 'menor_precio',
  error: null,
  loading: true
};

const productSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setOrderBy(state, action) {
      state.orderBy = action.payload;
    },
    setProducts(state, action) {
      return action.payload;
    },
    setFilter(state, action) {
      state.filtersActive = action.payload;
    },

    removeFilter(state, action) {
      state.filtersActive = action.payload;
    }
  }
});

export const { setOrderBy, setProducts, setFilter, removeFilter } =
  productSlice.actions;
export default productSlice.reducer;
