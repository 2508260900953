/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = { isDesktop: false };

const machineInformationSlice = createSlice({
  name: 'machineInformation',
  initialState,
  reducers: {}
});
export default machineInformationSlice.reducer;
