import parsePriceToNumber from '@/utils/parsePriceToNumber';

/* eslint-disable camelcase */
const pageview = () => {
  window.fbq('track', 'PageView');
};

const viewContent = (product, eventID) => {
  const { name, category, id, price } = product;

  window.fbq(
    'track',
    'ViewContent',
    {
      content_name: name,
      content_category: category,
      content_ids: [id],
      content_type: 'product_group',
      value: price,
      currency: 'ARS'
    },
    { eventID }
  );
};

const viewLanding = (name, eventID) => {
  window.fbq('trackCustom', 'ViewLanding', { landing: name }, { eventID });
};

const addToCart = (product, eventID) => {
  const { name, id, price } = product;

  window.fbq(
    'track',
    'AddToCart',
    {
      content_name: name,
      content_ids: [id],
      content_type: 'product_group',
      value: price,
      currency: 'ARS'
    },
    {
      eventID
    }
  );
};

const completeRegistration = (eventID) => {
  window.fbq('track', 'CompleteRegistration', {}, { eventID });
};

const subscribe = (eventID) => {
  window.fbq('track', 'Subscribe', {}, { eventID });
};

const purchase = (cart, eventID) => {
  const { line_items, total } = cart;
  const items = line_items.map((x) => ({
    id: x.sku,
    quantity: x.quantity,
    item_price: parsePriceToNumber(x.prices?.sale_price ?? x.prices.original)
  }));

  window.fbq(
    'track',
    'Purchase',
    {
      value: parsePriceToNumber(total),
      currency: 'ARS',
      contents: items,
      content_type: 'product_group'
    },
    { eventID }
  );
};

export default {
  pageview,
  viewContent,
  viewLanding,
  addToCart,
  completeRegistration,
  subscribe,
  purchase
};
