/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  active: 'av._san_martin_1390_vaypol',
  lat: '-32.887533',
  lng: '-68.838865'
};

const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setActive(state, action) {
      state.active = action.payload.address;
      state.lat = action.payload.lat;
      state.lng = action.payload.lng;
    }
  }
});

export const { setActive } = mapSlice.actions;

export default mapSlice.reducer;
